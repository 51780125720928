import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import BioTile from '@/components/BioTile';
import Container from '@/components/Container';
import GradientText from '@/components/GradientText';
import HeaderTile from '@/components/HeaderTile';
import ImageTile from '@/components/ImageTile';
import TileWrapper from '@/components/TileWrapper';

const Team: React.FC = () => (
  <Container tag="section" id="team">
    <TileWrapper className="md:grid md:grid-cols-2 xl:grid-cols-3">
      <HeaderTile
        theme="light"
        className="order-first bg-gray-200 md:col-span-2 xl:col-span-3"
        title="Our Team"
        image={
          <StaticImage
            src="../../resources/images/cubes-04.png"
            alt="3d geometric cubes flying around"
            className="object-cover w-full h-full"
            width={900}
          />
        }
      >
        <GradientText>The people</GradientText> driving the next level of
        ancillary growth
      </HeaderTile>
      <ImageTile>
        <StaticImage
          src="../../resources/images/portrait-hoppin.jpg"
          alt="Portrait of David Hoppin"
          className="object-cover w-full h-full"
          width={600}
        />
      </ImageTile>
      <BioTile
        name="David Hoppin"
        position="Chief Executive Officer"
        theme="color"
      >
        David has an extensive background with data and web solutions, having
        previously been CEO at Diio and leading the development of IATA DDS data
        (with 10 billion+ records). After the sale of Diio, David has now
        shifted his focus to ancillary revenues.
      </BioTile>
      <ImageTile className="hidden xl:block">
        <StaticImage
          src="../../resources/images/wing.jpg"
          alt="Wing of a flying plane"
          className="object-cover w-full h-full"
          width={600}
        />
      </ImageTile>
      <ImageTile className="hidden xl:block" theme="dark">
        <StaticImage
          src="../../resources/images/logo-mark-tile.png"
          alt="AncillaryBox Logo mark"
          className="object-cover w-full h-full"
          width={600}
        />
      </ImageTile>
      <ImageTile>
        <StaticImage
          src="../../resources/images/portrait-walbridge.jpg"
          alt="Portrait of Kim Walbridge"
          className="object-cover w-full h-full"
          width={600}
        />
      </ImageTile>
      <BioTile name="Kim Walbridge" position="Chief Customer Officer">
        Kim has 20+ years leadership experience in digital strategy, marketing
        and e-commerce. In 16 years at Air New Zealand he led numerous projects
        to grow direct revenue and margin, including global market expansion,
        new commercial propositions and ancillary partner integrations.
      </BioTile>
      <ImageTile>
        <StaticImage
          src="../../resources/images/portrait-brzeski.jpg"
          alt="Portrait of Swavek Brzeski"
          className="object-cover w-full h-full"
          width={600}
        />
      </ImageTile>
      <BioTile
        name="Swavek Brzeski"
        position="Chief Technology Officer"
        theme="dark"
      >
        The former Director Revenue Business Intelligence at United Airlines,
        Swavek has spent the last twenty years focused on business intelligence,
        data analytics and developing of web solutions.
      </BioTile>
      <ImageTile className="hidden xl:block">
        <StaticImage
          src="../../resources/images/tile-clouds.jpg"
          alt="Clouds"
          className="object-cover w-full h-full"
          width={600}
        />
      </ImageTile>
      <ImageTile className="hidden xl:block">
        <StaticImage
          src="../../resources/images/tile-plane-clean.jpg"
          alt="Plane standing on the runway"
          className="object-cover w-full h-full"
          width={600}
        />
      </ImageTile>
      <ImageTile>
        <StaticImage
          src="../../resources/images/portrait-fischer.jpg"
          alt="Portrait of Christina Fischer"
          className="object-cover w-full h-full"
          width={600}
        />
      </ImageTile>
      <BioTile
        name="Christina Fischer"
        position="Advisory Board Member"
        theme="color"
      >
        Christina has more than twenty years experience in aviation, e-commerce,
        sales, digital marketing and social media. She has been working for
        Star Alliance, in the USA and Germany, managing airline boards with
        representatives of up to 26 airlines, as well as digital agencies and
        global IT vendors.
      </BioTile>
      <ImageTile>
        <StaticImage
          src="../../resources/images/portrait-gaede.jpg"
          alt="Portrait of Dagmar Gaede"
          className="object-cover w-full h-full"
          width={600}
        />
      </ImageTile>
      <BioTile name="Dagmar Gaede" position="Advisory Board Member">
        Dagmar has 10+ years experience as a senior leader in digital and
        e-commerce payment products. She held leadership roles at PayPal,
        J.D.Power &amp; Associates/McGraw-Hill and Oxford University. Dagmar
        holds an MA (Oxon) and MBA from Oxford University, where she also
        studied as a Rhodes Scholar for Germany.
      </BioTile>
      <ImageTile className="hidden xl:block" theme="dark">
        <StaticImage
          src="../../resources/images/logo-mark-tile.png"
          alt="AncillaryBox Logo mark"
          className="object-cover w-full h-full"
          width={600}
        />
      </ImageTile>
      <ImageTile className="hidden xl:block">
        <StaticImage
          src="../../resources/images/logo-tile.png"
          alt="AncillaryBox Logo"
          className="object-cover w-full h-full"
          width={600}
        />
      </ImageTile>
      <ImageTile>
        <StaticImage
          src="../../resources/images/tile-plane-centered.jpg"
          alt="Plane standing on the runway"
          className="object-cover w-full h-full"
          width={600}
        />
      </ImageTile>
      <ImageTile className="hidden bg-primary-600 md:block" />
    </TileWrapper>
  </Container>
);

export default Team;
